import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  max as rule_max,
  min_value as rule_min_value,
} from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorRuc,
  validatorTel,
  validatorDoc,
  validatorMoney,
  validatorPorcentage,
  validatorIsMaxVal,

} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const max = extend('max', rule_max)

export const minValue = extend('min_value', rule_min_value)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'El número debe ser positivo',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Tu {_field_} debe contener al menos una mayúscula, una minúscula y un carácter especial(!@#$%&*())',
})

export const ruc = extend('ruc', {
  validate: validatorRuc,
  message: 'No es un número valido de RUC',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const tel = extend('phone', {
  validate: validatorTel,
  params: ['type'],
  message: 'El {_field_} es incorrecto',
})

export const document = extend('document', {
  validate: validatorDoc,
  params: ['type'],
  message: 'El {_field_} es incorrecto',
}, {
  hasTarget: true,
})

export const money = extend('money', {
  validate: validatorMoney,
  message: 'El formato es incorrecto',
})

export const porcentage = extend('porcentage', {
  validate: validatorPorcentage,
  message: 'El {_field_} debe ser menor o igual a 100',
})

export const isMaxVal = extend('isMaxVal', {
  validate: validatorIsMaxVal,
  message: 'El importe neto a pagar debe ser menor o igual al importe original',
})

export const isMaxValXML = extend('isMaxVal', {
  validate: validatorIsMaxVal,
  message: 'El importe de pago de la cuota debe ser menor o igual al importe consignado en el XML',
})

localize('es', es)
