export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorRuc = val => {
  const cRegExp = /^[0-9]{11}$/
  /* eslint-enable no-useless-escape */
  const validRuc = cRegExp.test(val)
  const subVal = val.substring(0, 2)
  if (subVal !== '10' && subVal !== '20') {
    return false
  }
  return validRuc
}

export const validatorTel = (val, { type }) => {
  let cRegExp = /^[0-9()+]{9,15}$/
  if (type !== '0') {
    cRegExp = /^[0-9()+]{7,9}$/
  }
  /* eslint-enable no-useless-escape */
  const validTel = cRegExp.test(val)
  return validTel
}

export const validatorDoc = (val, { type }) => {
  let cRegExp = ''
  switch (type) {
    case '0':
      // Todos los casos
      cRegExp = /^[a-zA-Z0-9]{8,15}$/
      break
    case '1':
      cRegExp = /^[0-9]{8}$/
      break
    case '2':
      cRegExp = /^[a-zA-Z0-9]{1,15}$/
      break
    case '3':
      cRegExp = /^[a-zA-Z0-9]{1,15}$/
      break
    case '4':
      cRegExp = /^[a-zA-Z0-9]{1,12}$/
      break
    case '6':
      cRegExp = /^[0-9]{11}$/
      break
    case '7':
      cRegExp = /^[a-zA-Z0-9]{1,12}$/
      break
    default:
      break
  }
  /* eslint-enable no-useless-escape */
  const validDoc = cRegExp.test(val)
  const subVal = val.substring(0, 2)
  if (type === '6' && subVal !== '10' && subVal !== '20') {
    return false
  }
  return validDoc
}

export const validatorMoney = val => {
  const cRegExp = /^\d{1,10}(\.\d{1,2})?$/
  const validMoney = cRegExp.test(val)
  return validMoney
}

export const validatorPorcentage = val => {
  if (parseInt(val, 10) < 0 || parseInt(val, 10) > 100) {
    return false
  }
  return true
}

export const validatorIsMaxVal = (value, maxVal) => {
  if (value <= parseFloat(maxVal[0])) {
    return true
  }
  return false
}
